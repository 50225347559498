import {
  H1,
  H2,
  InteractionContainer,
  JPButton
} from '../components/OldGeneralComponents';
import { NewH2, NewH4, NewJPButton, Text2XL, TextXL } from '@/components/GeneralComponents';
import { PageNames } from '../utils/generalUtilities';
import { useRouter } from 'next/router';
import Icon from '@/components/Shared/Icon';
import JPHeader from '../components/Shared/JPHeader';
import React from 'react';
import locale from '../locales/en/NativeLanding';

const ErrorPage = () => {
  const router = useRouter();
  return (
    <>
      <InteractionContainer maxWidth="600px">
        <Icon
          type="ft-alert-circle"
          size={24}
          className="mb-2 mt-20 text-red-500"
              />
        <Text2XL className='font-medium'> Looks like you’ve hit a dead end.</Text2XL>
        <p className='text-center text-md text-gray-500 mt-2'> 
          Can&#39;t find what you&#39;re looking for? Contact <a href="mailto:hello@jupitrr.com" className="text-blue-500 underline">hello@jupitrr.com</a> for support.
        </p>
        <NewJPButton className='mt-4'
          text='Back to Home'
          size="sm"
          onClick={() => router.push(PageNames.ROOT)}
        />
      </InteractionContainer>
    </>
  );
};

export default ErrorPage;
